// RichComments.js

import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Typography,
  List,
  ListItem,
  IconButton,
  Stack,
  Avatar,
  Tooltip,
  Popover,
  Button,
} from "@mui/material";
import { useAuth } from "../../../firebase/contexts/AuthContext";
import { getDB } from "../../../firebase/firebase";
import { get, ref, update } from "firebase/database";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { format } from "date-fns";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  agnosticCommandEnter,
  convertToArray,
  sendEmail,
  themeDetails,
} from "../../../Helper/utils";
import "./Comments.css";
import TextRenderer from "./TextRenderer";
import CloseIcon from "@mui/icons-material/Close";
import EmojiPicker from "emoji-picker-react";
import {
  ReactionTooltip,
  SquaredButtonGreen,
  getAddCommentTextField,
} from "../../../MUI_Components/Components";
import ReactionTooltipComponent from "./ReactionTooltipComponent";
import { useSettings } from "../../../Contexts/SettingsContext";
import { EditorContent, ReactRenderer, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import { styled } from "@mui/material/styles";
import MentionList from "./MentionList";
import { Extension } from "@tiptap/core";

const StyledEditorContent = styled(EditorContent)(({ theme, settings }) => ({
  border: "1px solid #ccc",
  borderRadius: "50px", // initial rounded corners
  padding: "2px 10px",
  backgroundColor: themeDetails[settings.cardColor]?.primary || "white",
  color: themeDetails[settings.cardColor]?.font || "black",
  width: "95%",
  transition: "border-radius 0.2s ease", // smooth transition for corner changes
  "&:focus": {
    outline: "none",
    borderColor: theme.palette.primary.main,
  },
  "& .ProseMirror": {
    outline: "none",
    minHeight: "20px",
    maxHeight: "150px", // max height for textfield
    overflowY: "auto",
    lineHeight: "1.5",
    transition: "border-radius 0.2s ease",
    borderRadius: (props) => (props.scrollHeight > 100 ? "3px" : "50px"), // less rounded as height increases
  },
  "& .ProseMirror p": {
    margin: 0,
  },
  "& .ProseMirror .placeholder": {
    color: "#888",
    margin: 0,
  },
  "& .ProseMirror": {
    color: themeDetails[settings.cardColor]?.font || "black",
    margin: 0,
  },
  "& .ProseMirror .mention": {
    backgroundColor: "#A0AD39",
    borderRadius: "8px",
    padding: "3px 6px",
    color: "white",
  },
}));

const RichComments = ({
  taskTitle,
  collaborators,
  comments,
  setComments,
  boardId,
  columnId,
  taskId,
  boardOwner,
  logAudit,
  taskName,
  boardTitle,
  taskPath,
}) => {
  const [editIndex, setEditIndex] = useState(null);
  const [replyIndex, setReplyIndex] = useState(null);
  const [react, setReact] = useState({ index: null, replyIndex: null });
  const [showReplies, setShowReplies] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentUser } = useAuth();
  const db = getDB();
  const inputRef = useRef(null);
  const [replyingTo, setReplyingTo] = useState("");
  const { settings } = useSettings();
  const commentsRef = useRef(comments);
  const editIndexRef = useRef(editIndex);
  const replyIndexRef = useRef(replyIndex);

  useEffect(() => {
    editIndexRef.current = editIndex;
  }, [editIndex]);

  useEffect(() => {
    replyIndexRef.current = replyIndex;
  }, [replyIndex]);

  useEffect(() => {
    commentsRef.current = comments;
  }, [comments]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "MMM d 'at' h:mm a");
  };

  // Ensure collaborators is an array of objects with 'name', 'email', 'avatar' properties
  const collaboratorsArray = Object.values(collaborators).map((user) => ({
    name: user.displayName || user.email,
    email: user.email,
    avatar: user.photoURL || "",
  }));

  const mentionSuggestion = useMemo(
    () => ({
      char: "@",
      startOfLine: false,
      allowSpaces: false,
      command: ({ editor, range, props }) => {
        editor
          .chain()
          .focus()
          .insertContentAt(range, [
            {
              type: "mention",
              attrs: { id: props.email, label: props.name },
            },
            { type: "text", text: " " },
          ])
          .run();
      },
      items: ({ query }) => {
        const collaboratorsArray = Object.values(collaborators).map((user) => ({
          name: user.displayName || user.email,
          email: user.email,
          avatar: user.photoURL || "",
        }));

        return collaboratorsArray
          .filter((item) =>
            item.name.toLowerCase().includes(query.toLowerCase())
          )
          .slice(0, 5);
      },
      render: () => {
        let reactRenderer;
        let popup;

        return {
          onStart: (props) => {
            reactRenderer = new ReactRenderer(MentionList, {
              props,
              editor: props.editor,
            });

            popup = tippy("body", {
              getReferenceClientRect: props.clientRect,
              appendTo: () => document.body,
              content: reactRenderer.element,
              showOnCreate: true,
              interactive: true,
              trigger: "manual",
              placement: "bottom-start",
            })[0]; // Access the first (and only) instance
          },
          onUpdate(props) {
            reactRenderer.updateProps(props);

            popup.setProps({
              getReferenceClientRect: props.clientRect,
            });
          },
          onKeyDown(props) {
            return reactRenderer.ref?.onKeyDown(props.event) || false;
          },
          onExit() {
            if (popup) {
              popup.destroy();
            }
            reactRenderer.destroy();
          },
        };
      },
    }),
    [collaborators]
  );

  const mentionExtension = Mention.configure({
    suggestion: mentionSuggestion,
    HTMLAttributes: {
      class: "mention",
    },
    parseHTML: [
      {
        tag: 'span[data-type="mention"]',
        getAttrs: (dom) => {
          const email = dom.getAttribute("data-id");
          const label = dom.getAttribute("data-label");
          return { id: email, label: label };
        },
      },
    ],
    renderHTML: ({ node, HTMLAttributes }) => {
      return [
        "span",
        {
          ...HTMLAttributes,
          "data-type": "mention",
          "data-id": node.attrs.id,
          "data-label": node.attrs.label,
          class: "mention",
        },
        `@${node.attrs.label}`, // Include '@'
      ];
    },
  });

  const parseStoredTextToEditorContent = (inputText) => {
    const mentionPattern = /@\[(.+?)\]\((.+?)\)/g;
    const html = inputText.replace(
      mentionPattern,
      (match, displayName, email) => {
        return `<span data-type="mention" data-id="${email}" data-label="${displayName}" class="mention">${displayName}</span>`;
      }
    );
    return html;
  };

  const commentEditor = useEditor({
    extensions: [
      StarterKit,
      mentionExtension,
      Placeholder.configure({
        placeholder: "Add a comment...",
      }),
      Extension.create({
        addKeyboardShortcuts() {
          return {
            "Cmd-Enter": () => {
              const content = commentEditor.getHTML();
              if (content && content !== "<p></p>") {
                console.log("123");
                handleAddComment();
              }
              return true; // Prevent default behavior
            },
            "Ctrl-Enter": () => {
              const content = commentEditor.getHTML();
              if (content && content !== "<p></p>") {
                handleAddComment();
              }
              return true; // Prevent default behavior
            },
          };
        },
      }),
    ],
    content: "",
  });

  const editCommentEditor = useEditor({
    extensions: [
      StarterKit,
      mentionExtension,
      Placeholder.configure({
        placeholder: "Edit comment...",
      }),
      Extension.create({
        addKeyboardShortcuts() {
          return {
            "Cmd-Enter": () => {
              const content = editCommentEditor.getHTML();
              if (content && content !== "<p></p>") {
                handleSaveComment(editIndexRef.current);
              }
              return true;
            },
            "Ctrl-Enter": () => {
              const content = editCommentEditor.getHTML();
              if (content && content !== "<p></p>") {
                handleSaveComment(editIndexRef.current);
              }
              return true;
            },
          };
        },
      }),
    ],
    content: "",
  });

  const replyEditor = useEditor({
    extensions: [
      StarterKit,
      mentionExtension,
      Placeholder.configure({
        placeholder: "Add a reply...",
      }),
      Extension.create({
        addKeyboardShortcuts() {
          return {
            "Cmd-Enter": () => {
              const content = replyEditor.getHTML();
              if (content && content !== "<p></p>") {
                handleAddReply(replyIndexRef.current);
              }
              return true;
            },
            "Ctrl-Enter": () => {
              const content = replyEditor.getHTML();
              if (content && content !== "<p></p>") {
                handleAddReply(replyIndexRef.current);
              }
              return true;
            },
          };
        },
      }),
    ],
    content: "",
  });

  const parseMentions = (input) => {
    const div = document.createElement("div");
    div.innerHTML = input;
    const mentions = div.querySelectorAll("span.mention");
    mentions.forEach((mention) => {
      const email = mention.getAttribute("data-id");
      const displayName = mention.innerText.replace(/^@/, ""); // Remove leading '@' if present
      mention.replaceWith(`@[${displayName}](${email})`);
    });
    return div.innerHTML;
  };

  const parseMentionsToText = (input) => {
    // First, process any HTML mentions
    const div = document.createElement("div");
    div.innerHTML = input;
    const mentions = div.querySelectorAll("span.mention");
    mentions.forEach((mention) => {
      const displayName =
        mention.getAttribute("data-label") || mention.innerText;

      // Remove any leading '@' to prevent duplication
      let name = displayName.startsWith("@")
        ? displayName.slice(1)
        : displayName;

      mention.replaceWith(`@${name}`);
    });

    // Get the text content
    let textContent = div.textContent || div.innerText || "";

    // Decode any HTML entities
    const textarea = document.createElement("textarea");
    textarea.innerHTML = textContent;
    textContent = textarea.value;

    // Replace any patterns of the form @[name](email) or variations with @name
    textContent = textContent.replace(
      /@\[?(.*?)\]?(\(|&lpar;).*?(\)|&rpar;)/g,
      "@$1"
    );

    return textContent;
  };

  const handleMentions = async (text, updatedComments) => {
    const mentionPattern = /@\[(.+?)\]\((.+?)\)/g;
    let match;
    const functions = getFunctions();
    const addActivity = httpsCallable(functions, "addActivity");

    while ((match = mentionPattern.exec(text)) !== null) {
      const name = match[1];
      const email = match[2];
      const mentionedUser = convertToArray(collaborators).find(
        (user) => user.email === email
      );
      if (mentionedUser) {
        await addActivity({
          boardOwner: boardOwner === "own" ? currentUser.uid : boardOwner,
          boardId,
          mentionedUser: mentionedUser.email,
          displayName: name,
          taskId,
          columnId,
          type: "comment",
          mentionedBy: currentUser.uid,
        });
        sendEmail(mentionedUser.email, "mentioned in comment", {
          boardName: boardTitle,
          fromEmail: currentUser.email,
          cardName: taskName,
          boardID: boardId,
          cardID: taskId,
          comment: parseMentionsToText(text),
          taskTitle: taskTitle,
        });
      }
    }
  };

  const handleAddComment = async () => {
    const textContent = commentEditor.getText();
    if (!textContent.trim()) {
      return;
    }

    const content = commentEditor.getHTML();
    const parsedComment = parseMentions(content);
    const newComment = {
      text: parsedComment,
      author: currentUser.email,
      createdAt: new Date().toISOString(),
      reactions: [],
      replies: [],
    };
    const updatedComments = [newComment, ...commentsRef.current];
    setComments(updatedComments);
    commentEditor.commands.clearContent();

    const taskRef = ref(db, `${taskPath}`);
    await update(taskRef, { comments: updatedComments });

    handleMentions(parsedComment, updatedComments);
    logAudit(
      "Comment added",
      `${
        currentUser.displayName || currentUser.email
      } commented ${parseMentionsToText(newComment.text)} on card ${taskName}`
    );
  };

  const handleSaveComment = async (index) => {
    if (index === null || index === undefined) return;

    const textContent = editCommentEditor.getText();
    if (!textContent.trim()) {
      return;
    }

    const content = editCommentEditor.getHTML();
    const parsedComment = parseMentions(content);
    const updatedComments = commentsRef.current.map((comment, i) =>
      i === index ? { ...comment, text: parsedComment } : comment
    );
    setComments(updatedComments);
    setEditIndex(null);
    editCommentEditor.commands.clearContent();

    const taskRef = ref(db, `${taskPath}`);
    await update(taskRef, { comments: updatedComments });

    handleMentions(parsedComment, updatedComments);
  };

  const handleAddReply = async (commentIndex) => {
    if (commentIndex === null || commentIndex === undefined) return;

    const textContent = replyEditor.getText();
    if (!textContent.trim()) {
      return;
    }

    const content = replyEditor.getHTML();
    const parsedReply = parseMentions(content);

    setReplyIndex(null);
    replyEditor.commands.clearContent();

    const newReply = {
      text: parsedReply,
      author: currentUser.email,
      createdAt: new Date().toISOString(),
      reactions: [],
    };

    let updatedComments = [...commentsRef.current];
    updatedComments[commentIndex].replies = [
      newReply,
      ...(updatedComments[commentIndex].replies || []),
    ];

    setComments(updatedComments);

    const taskRef = ref(db, `${taskPath}`);
    await update(taskRef, { comments: updatedComments });

    handleMentions(parsedReply, updatedComments);

    const functions = getFunctions();
    const addActivity = httpsCallable(functions, "addActivity");

    if (replyingTo !== currentUser.email) {
      await addActivity({
        boardOwner: boardOwner === "own" ? currentUser.uid : boardOwner,
        boardId,
        mentionedUser: replyingTo,
        displayName: replyingTo,
        columnId,
        type: "reply",
        taskId: taskId,
        mentionedBy: currentUser.uid,
      });
      logAudit(
        "Reply added",
        `${
          currentUser.displayName || currentUser.email
        } replied ${parseMentionsToText(parsedReply)} to ${parseMentionsToText(
          updatedComments[commentIndex].text
        )} on card ${taskName}`
      );
    }

    setReplyingTo("");
  };

  const handleEditComment = (index) => {
    setEditIndex(index);
    const comment = comments[index];
    const editorContent = parseStoredTextToEditorContent(comment.text);
    editCommentEditor.commands.setContent(editorContent);
  };

  const handleReply = (index) => {
    setReplyIndex(index);
  };

  function handleReplyReply(index, replyUser) {
    setReplyIndex(index);
    handleSetReplyingTo(replyUser);

    let replyAuthor = convertToArray(collaborators).find(
      (user) => user.email === replyUser
    );

    if (replyEditor) {
      replyEditor.commands.clearContent();
      replyEditor
        .chain()
        .focus()
        .insertContent([
          {
            type: "mention",
            attrs: {
              id: replyAuthor.email,
              label: replyAuthor.displayName || replyAuthor.email,
            },
          },
          {
            type: "text",
            text: " ",
          },
        ])
        .run();

      setTimeout(() => {
        replyEditor.commands.focus();
        replyEditor.commands.setTextSelection(
          replyEditor.state.doc.content.size
        );
      }, 0);
    }
  }

  const handleSetReplyingTo = (person) => {
    setReplyingTo(person);
  };

  const handleDeleteComment = async (index) => {
    const updatedComments = comments.filter((_, i) => i !== index);
    setComments(updatedComments);

    const taskRef = ref(db, `${taskPath}`);
    await update(taskRef, { comments: updatedComments });
  };

  const handleDeleteReply = async (commentIndex, replyIndex) => {
    const updatedComments = comments.map((comment, i) => {
      if (i === commentIndex) {
        const updatedReplies = comment.replies.filter(
          (_, j) => j !== replyIndex
        );
        return { ...comment, replies: updatedReplies };
      }
      return comment;
    });
    setComments(updatedComments);

    const taskRef = ref(db, `${taskPath}`);
    await update(taskRef, { comments: updatedComments });
  };

  const getUserInfoByEmail = (email, users) => {
    const userEntry = Object.entries(users).find(
      ([uid, userInfo]) => userInfo.email === email
    );
    if (userEntry) {
      const [uid, userInfo] = userEntry;
      return { uid, ...userInfo };
    }
    return null;
  };

  const handleAddReact = async (emoji, commentIndex, replyIndex = null) => {
    setReact({ index: null, replyIndex: null });
    setAnchorEl(null);

    const newReaction = {
      emoji: emoji,
      author: currentUser.email,
      createdAt: new Date().toISOString(),
    };

    const taskRef = ref(db, `${taskPath}`);
    const snapshot = await get(taskRef);

    let updatedComments = [...comments];
    let reactions;

    if (replyIndex !== null) {
      reactions =
        updatedComments[commentIndex].replies[replyIndex].reactions || [];
    } else {
      reactions = updatedComments[commentIndex].reactions || [];
    }

    const reactionExists = reactions.some(
      (reaction) =>
        reaction.emoji === emoji && reaction.author === currentUser.email
    );

    if (!reactionExists) {
      if (replyIndex !== null) {
        updatedComments[commentIndex].replies[replyIndex].reactions = [
          newReaction,
          ...reactions,
        ];
      } else {
        updatedComments[commentIndex].reactions = [newReaction, ...reactions];
      }

      setComments(updatedComments);
      await update(taskRef, { comments: updatedComments });

      logAudit(
        "Reaction added",
        `${
          currentUser.displayName || currentUser.email
        } reacted ${emoji} to ${parseMentionsToText(
          updatedComments[commentIndex].text
        )} on card ${taskName}`
      );
    }
  };

  const handleDeleteReact = async (commentIndex, emoji, replyIndex = null) => {
    const updatedComments = comments.map((comment, i) => {
      if (i === commentIndex) {
        if (replyIndex !== null) {
          const updatedReactions = comment.replies[replyIndex].reactions.filter(
            (reaction) =>
              !(
                reaction.author === currentUser.email &&
                reaction.emoji === emoji
              )
          );
          return {
            ...comment,
            replies: comment.replies.map((reply, j) =>
              j === replyIndex
                ? { ...reply, reactions: updatedReactions }
                : reply
            ),
          };
        } else {
          const updatedReactions = comment.reactions.filter(
            (reaction) =>
              !(
                reaction.author === currentUser.email &&
                reaction.emoji === emoji
              )
          );
          return { ...comment, reactions: updatedReactions };
        }
      }
      return comment;
    });

    setComments(updatedComments);

    const taskRef = ref(db, `${taskPath}`);
    await update(taskRef, { comments: updatedComments });
  };

  const handleOpenPopover = (event, index, replyIndex = null) => {
    setAnchorEl(event.currentTarget);
    setReact({ index, replyIndex });
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setReact({ index: null, replyIndex: null });
  };

  const shouldShowAvatar = (current, previous) => {
    if (!previous) return true;

    if (current?.replies?.length > 0 || previous?.replies?.length > 0) {
      return true;
    }

    const currentTime = new Date(current.createdAt);
    const previousTime = new Date(previous.createdAt);
    const timeDifference = (previousTime - currentTime) / (1000 * 60); // Difference in minutes

    let shouldShow = timeDifference > 5 || current.author !== previous.author;

    return shouldShow;
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <ChatBubbleOutlineIcon />
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Comments
        </Typography>
      </Stack>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          marginTop: "20px",
        }}
      >
        <Avatar src={currentUser.photoURL} sx={{ marginRight: "10px" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "right",
            width: "100%",
          }}
        >
          <StyledEditorContent editor={commentEditor} settings={settings} />
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginTop: "3px",
            }}
          >
            <SquaredButtonGreen
              onClick={handleAddComment}
              sx={{
                height: "30px",
                textTransform: "none",
                marginRight: "10px",
                marginTop: 1,
              }}
            >
              Save
            </SquaredButtonGreen>
          </div>
        </div>
      </div>

      <List>
        {comments.map((comment, index) => {
          let user = getUserInfoByEmail(comment.author, collaborators);
          let showAvatar = shouldShowAvatar(
            comment,
            comments[index - 1] || null
          );

          const reactionCounts = comment?.reactions?.reduce((acc, reaction) => {
            if (!reaction || !reaction.emoji || !reaction.author) return acc;

            if (!acc[reaction.emoji]) {
              acc[reaction.emoji] = {
                count: 0,
                indices: [],
                isAuthor: false,
                authors: [],
              };
            }
            acc[reaction.emoji].count += 1;
            acc[reaction.emoji].indices.push(index);
            acc[reaction.emoji].authors.push(reaction.author);
            if (reaction.author === currentUser.email) {
              acc[reaction.emoji].isAuthor = true;
            }
            return acc;
          }, {});

          return (
            <ListItem key={index} sx={{ padding: 0, marginBottom: "5px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                {showAvatar ? (
                  <Tooltip
                    title={user?.displayName || user?.email.split("@")[0]}
                  >
                    <Avatar
                      src={user?.avatar}
                      alt={user?.name || user?.email.split("@")[0]}
                      sx={{
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                      }}
                    />
                  </Tooltip>
                ) : (
                  <div style={{ width: "55px" }} />
                )}
                <div style={{ width: "100%" }}>
                  {showAvatar && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-start",
                        marginBottom: "3px",
                      }}
                    >
                      <Typography
                        sx={{
                          mr: "10px",
                          fontSize: "11pt",
                          fontWeight: "bold",
                        }}
                      >
                        {user?.displayName || user?.email.split("@")[0]}
                      </Typography>
                      <Typography sx={{ fontSize: "8pt" }}>
                        {formatDate(comment.createdAt)}
                      </Typography>
                    </div>
                  )}
                  {editIndex === index ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "right",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor:
                            themeDetails[settings.cardColor]?.primary,
                          width: "95%",
                          minHeight: "40px",
                          borderRadius: "9px",
                          alignItems: "center",
                          padding: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <StyledEditorContent
                          editor={editCommentEditor}
                          settings={settings}
                        />
                        <IconButton
                          onClick={() => handleSaveComment(index)}
                          sx={{ color: themeDetails[settings.cardColor]?.font }}
                        >
                          <ArrowCircleUpIcon />
                        </IconButton>
                      </div>
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div
                        style={{
                          backgroundColor:
                            themeDetails[settings.cardColor]?.primary ||
                            "white",
                          color:
                            themeDetails[settings.cardColor]?.font || "black",
                          width: "calc(100% - 20px)",
                          height: "auto",
                          borderRadius: "25px",
                          alignItems: "center",
                          padding: "10px",
                          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        }}
                      >
                        <Popover
                          open={Boolean(anchorEl)}
                          anchorEl={anchorEl}
                          onClose={handleClosePopover}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <EmojiPicker
                            onEmojiClick={(e) =>
                              handleAddReact(
                                e.emoji,
                                react.index,
                                react.replyIndex
                              )
                            }
                          />
                        </Popover>

                        <ReactionTooltip
                          placement="top-end"
                          open={tooltipOpen === index}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor:
                                  themeDetails[settings.cardColor]?.primary ||
                                  "white",
                                borderRadius: "20px",
                                color: "white",
                                boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                                opacity: 1,
                              },
                            },
                          }}
                          onOpen={() => setTooltipOpen(index)}
                          onClose={() => setTooltipOpen(false)}
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [100, -14],
                                  },
                                },
                              ],
                            },
                          }}
                          title={
                            <ReactionTooltipComponent
                              setTooltipOpen={setTooltipOpen}
                              handleAddReact={handleAddReact}
                              handleOpenPopover={handleOpenPopover}
                              index={index}
                              handleReply={handleReply}
                              handleEditComment={handleEditComment}
                              commentAuthor={comment.author}
                              handleDeleteComment={handleDeleteComment}
                              boardOwner={boardOwner}
                              boardId={boardId}
                              columnId={columnId}
                              setReplyingTo={handleSetReplyingTo}
                              handleReplyReply={handleReplyReply}
                            />
                          }
                        >
                          <Typography
                            sx={{
                              fontSize: "12pt",
                              width: "100%",
                              overflowWrap: "break-word",
                              marginLeft: 0.2,
                            }}
                          >
                            <TextRenderer
                              style={{ display: "flex", alignItems: "center" }}
                              text={comment.text}
                            />
                          </Typography>
                        </ReactionTooltip>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {comment?.reactions?.length > 0 && (
                          <div style={{ marginTop: "2px" }}>
                            {Object.entries(reactionCounts).map(
                              ([emoji, data]) => (
                                <Tooltip
                                  key={emoji}
                                  disableInteractive
                                  title={
                                    <>
                                      {data?.authors?.map((author) => {
                                        const replyUser = getUserInfoByEmail(
                                          author,
                                          collaborators
                                        );
                                        return (
                                          <Typography
                                            sx={{ textAlign: "center" }}
                                          >
                                            {replyUser?.displayName || author}
                                          </Typography>
                                        );
                                      })}
                                    </>
                                  }
                                  arrow
                                >
                                  <span
                                    onClick={() => {
                                      if (data.isAuthor) {
                                        handleDeleteReact(index, emoji);
                                      } else {
                                        handleAddReact(emoji, index);
                                      }
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      border: data.isAuthor
                                        ? "2px solid #A0AD39"
                                        : "2px solid lightgrey",
                                      borderRadius: "5px",
                                      padding: "2px 4px",
                                      margin: "2px",
                                      display: "inline-flex",
                                      alignItems: "center",
                                      backgroundColor: data.isAuthor
                                        ? "#E3E7BE"
                                        : "transparent",
                                    }}
                                  >
                                    {emoji}
                                    <span style={{ marginLeft: "6px" }}>
                                      {data.count}
                                    </span>
                                  </span>
                                </Tooltip>
                              )
                            )}
                          </div>
                        )}
                      </div>
                      <>
                        {replyIndex === index && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            <Avatar
                              src={currentUser.photoURL}
                              sx={{ marginRight: "10px" }}
                            />
                            <StyledEditorContent
                              editor={replyEditor}
                              settings={settings}
                            />
                            <IconButton
                              onClick={() => handleAddReply(index)}
                              sx={{
                                color: themeDetails[settings.cardColor]?.font,
                              }}
                            >
                              <ArrowCircleUpIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => setReplyIndex(null)}
                              sx={{
                                color: themeDetails[settings.cardColor]?.font,
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        )}
                      </>
                      {comment?.replies
                        ?.slice(0, showReplies ? comment.replies.length : 2)
                        .map((reply, replyIndex) => {
                          const replyUser = getUserInfoByEmail(
                            reply.author,
                            collaborators
                          );
                          let showAvatar = shouldShowAvatar(
                            reply,
                            comment?.replies?.slice(
                              0,
                              showReplies ? comment.replies.length : 2
                            )[replyIndex - 1] || null
                          );

                          const replyReactionCounts = reply?.reactions?.reduce(
                            (acc, reaction) => {
                              if (
                                !reaction ||
                                !reaction.emoji ||
                                !reaction.author
                              )
                                return acc;

                              if (!acc[reaction.emoji]) {
                                acc[reaction.emoji] = {
                                  count: 0,
                                  indices: [],
                                  isAuthor: false,
                                  authors: [],
                                };
                              }
                              acc[reaction.emoji].count += 1;
                              acc[reaction.emoji].indices.push(replyIndex);
                              acc[reaction.emoji].authors.push(reaction.author);
                              if (reaction.author === currentUser.email) {
                                acc[reaction.emoji].isAuthor = true;
                              }
                              return acc;
                            },
                            {}
                          );

                          return (
                            <div
                              key={replyIndex}
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                marginTop: "10px",
                              }}
                            >
                              {showAvatar ? (
                                <Tooltip
                                  title={
                                    replyUser?.name ||
                                    replyUser?.email.split("@")[0]
                                  }
                                >
                                  <Avatar
                                    src={replyUser?.avatar}
                                    alt={
                                      replyUser?.name ||
                                      replyUser?.email.split("@")[0]
                                    }
                                    sx={{
                                      width: "40px",
                                      height: "40px",
                                      marginRight: "10px",
                                    }}
                                  />
                                </Tooltip>
                              ) : (
                                <div style={{ width: "55px" }}></div>
                              )}
                              <div style={{ width: "100%" }}>
                                {showAvatar && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                      justifyContent: "flex-start",
                                      marginBottom: "3px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        mr: "10px",
                                        fontSize: "11pt",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {replyUser?.displayName ||
                                        replyUser?.email.split("@")[0]}
                                    </Typography>
                                    <Typography sx={{ fontSize: "8pt" }}>
                                      {formatDate(reply.createdAt)}
                                    </Typography>
                                  </div>
                                )}
                                <div
                                  style={{
                                    backgroundColor:
                                      themeDetails[settings.cardColor]
                                        ?.primary || "white",
                                    color:
                                      themeDetails[settings.cardColor]?.font ||
                                      "black",
                                    width: "calc(100% - 20px)",
                                    height: "auto",
                                    borderRadius: "25px",
                                    alignItems: "center",
                                    padding: "10px",
                                    marginLeft: "00px",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                                  }}
                                >
                                  <ReactionTooltip
                                    placement="top-end"
                                    open={
                                      tooltipOpen?.reply === true &&
                                      tooltipOpen?.index === index &&
                                      tooltipOpen?.replyIndex === replyIndex
                                    }
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          bgcolor:
                                            themeDetails[settings.cardColor]
                                              ?.primary || "white",
                                          borderRadius: "30px",
                                          color:
                                            themeDetails[settings.cardColor]
                                              ?.font || "black",
                                          boxShadow:
                                            "0px 10px 15px -3px rgba(0,0,0,0.1)",
                                          opacity: 1,
                                        },
                                      },
                                    }}
                                    onOpen={() =>
                                      setTooltipOpen({
                                        reply: true,
                                        index: index,
                                        replyIndex: replyIndex,
                                      })
                                    }
                                    onClose={() => setTooltipOpen(false)}
                                    slotProps={{
                                      popper: {
                                        modifiers: [
                                          {
                                            name: "offset",
                                            options: {
                                              offset: [100, -14],
                                            },
                                          },
                                        ],
                                      },
                                    }}
                                    title={
                                      <ReactionTooltipComponent
                                        setTooltipOpen={setTooltipOpen}
                                        handleAddReact={handleAddReact}
                                        handleOpenPopover={handleOpenPopover}
                                        index={index}
                                        replyIndex={replyIndex}
                                        handleReply={handleReply}
                                        author={reply.author}
                                        handleDeleteReply={handleDeleteReply}
                                        type="reply"
                                        replyAuthor={reply.author}
                                        setReplyingTo={handleSetReplyingTo}
                                        handleReplyReply={handleReplyReply}
                                      />
                                    }
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "12pt",
                                        width: "100%",
                                        overflowWrap: "break-word",
                                        marginLeft: 0.2,
                                      }}
                                    >
                                      <TextRenderer
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        text={reply.text}
                                      />
                                    </Typography>
                                  </ReactionTooltip>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  {reply?.reactions?.length > 0 && (
                                    <div style={{ marginTop: "2px" }}>
                                      {Object.entries(replyReactionCounts).map(
                                        ([emoji, data]) => (
                                          <Tooltip
                                            key={emoji}
                                            disableInteractive
                                            title={
                                              <>
                                                {data?.authors?.map(
                                                  (author) => {
                                                    const replyUser =
                                                      getUserInfoByEmail(
                                                        author,
                                                        collaborators
                                                      );
                                                    return (
                                                      <Typography
                                                        sx={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {replyUser?.displayName ||
                                                          author}
                                                      </Typography>
                                                    );
                                                  }
                                                )}
                                              </>
                                            }
                                            arrow
                                          >
                                            <span
                                              onClick={() => {
                                                if (data.isAuthor) {
                                                  handleDeleteReact(
                                                    index,
                                                    emoji,
                                                    replyIndex
                                                  );
                                                } else {
                                                  handleAddReact(
                                                    emoji,
                                                    index,
                                                    replyIndex
                                                  );
                                                }
                                              }}
                                              style={{
                                                cursor: "pointer",
                                                border: data.isAuthor
                                                  ? "2px solid #A0AD39"
                                                  : "2px solid lightgrey",
                                                borderRadius: "5px",
                                                padding: "2px 4px",
                                                margin: "2px",
                                                display: "inline-flex",
                                                alignItems: "center",
                                                backgroundColor: data.isAuthor
                                                  ? "#E3E7BE"
                                                  : "transparent",
                                              }}
                                            >
                                              {emoji}
                                              <span
                                                style={{ marginLeft: "6px" }}
                                              >
                                                {data.count}
                                              </span>
                                            </span>
                                          </Tooltip>
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {comment.replies?.length > 2 && (
                        <Typography
                          sx={{
                            fontSize: "9pt",
                            textDecoration: "underline",
                            cursor: "pointer",
                            marginTop: "5px",
                            textAlign: "center",
                          }}
                          onClick={() => setShowReplies((prev) => !prev)}
                        >
                          {showReplies ? "Hide replies" : "Show all replies"}
                        </Typography>
                      )}
                    </>
                  )}
                </div>
              </div>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default RichComments;
