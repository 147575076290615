import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
} from "@mui/material";
import { onValue, ref, update, remove, off } from "firebase/database";
import { getDB } from "../../firebase/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  RealDivider,
  SquaredButtonGreen,
  TeldioSwitch,
  TeldioTab,
  TeldioTabs,
  TeldioTextField,
} from "../../MUI_Components/Components";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import PropTypes from "prop-types";
import { convertToArray, themeDetails, themeOptions } from "../../Helper/utils";
import { useSettings } from "../../Contexts/SettingsContext";

const colors = [
  "#FFEB3B",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#F44336",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
];

function CustomTabPanel(props) {
  const { children, value, index, backgroundColor, color, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        backgroundColor: backgroundColor,
        color: color,
        height: "100%",
      }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SettingsSidebar = ({
  open,
  onClose,
  boardId,
  currentUser,
  boardOwner,
  showingArchived,
  setShowingArchived,
  showingCardCount,
  setShowingCardCount,
  reverseComments,
  setReverseComments,
  collaborators,
}) => {
  const [title, setTitle] = useState("");
  const [background, setBackground] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const db = getDB();
  const storage = getStorage();
  const functions = getFunctions();
  const [uploading, setUploading] = useState(false);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedColor, setSelectedColor] = useState();
  const { settings } = useSettings();

  const boardPath =
    boardOwner === "own"
      ? `/uid/${currentUser.uid}/boards/${boardId}`
      : `/uid/${boardOwner}/boards/${boardId}`;

  const fetchBoardSettings = () => {
    setLoading(true);
    const boardRef = ref(db, boardPath);
    const listener = onValue(boardRef, (snapshot) => {
      const boardData = snapshot.val();
      if (boardData) {
        if (boardOwner === "own") {
          setShowingArchived(boardData?.settings?.showArchived || false);
          // setShowingCardCount(
          //   boardData?.settings?.showCount !== undefined
          //     ? boardData.settings.showCount
          //     : true
          // );
          //setReverseComments(boardData?.settings?.reverseComments || false);
        } else {
          setShowingArchived(
            boardData?.collaborators[currentUser?.uid]?.showArchived || false
          );
          // setShowingCardCount(
          //   boardData?.collaborators[currentUser?.uid]?.showCount !== undefined
          //     ? boardData.collaborators[currentUser.uid].showCount
          //     : true
          // );
          // setReverseComments(
          //   boardData?.collaborators[currentUser?.uid]?.reverseComments || false
          // );
        }
        setTitle(boardData.title || "");
        setBackground(boardData.background || "");
        setLoading(false);
      }
    });

    return () => {
      off(boardRef, listener);
    };
  };

  useEffect(() => {
    if (open) {
      fetchBoardSettings();
    }
  }, [open]);

  useEffect(() => {
    setSelectedColor(settings?.cardColor || "white");
    setShowingCardCount(settings?.showCount);
    setReverseComments(settings?.reverseComments);
  }, [settings]);

  const handleColorChange = (color, title) => {
    setSelectedColor(title);
    const userSettingsPath = `/uid/${currentUser.uid}/settings`;
    const settingsRef = ref(db, userSettingsPath);
    update(settingsRef, { cardColor: title });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleBackgroundChange = (e) => {
    setBackground(e.target.value);
  };

  const handleToggleViewArchived = (e) => {
    const checked = e.target.checked;
    setShowingArchived(checked);
    if (boardOwner === "own") {
      update(ref(db, boardPath + "/settings"), {
        showArchived: checked,
      });
    } else {
      update(ref(db, boardPath + "/collaborators/" + currentUser.uid), {
        showArchived: checked,
      });
    }
  };

  const handleToggleReverseComments = (e) => {
    // const checked = e.target.checked;
    // setReverseComments(checked);
    // if (boardOwner === "own") {
    //   update(ref(db, boardPath + "/settings"), {
    //     reverseComments: checked,
    //   });
    // } else {
    //   update(ref(db, boardPath + "/collaborators/" + currentUser.uid), {
    //     reverseComments: checked,
    //   });
    // }

    const checked = e.target.checked;
    setReverseComments(checked);
    const userSettingsPath = `/uid/${currentUser.uid}/settings`;
    const settingsRef = ref(db, userSettingsPath);
    update(settingsRef, { reverseComments: checked });
  };

  const handleToggleShowCount = (e) => {
    const checked = e.target.checked;
    setShowingCardCount(checked);
    const userSettingsPath = `/uid/${currentUser.uid}/settings`;
    const settingsRef = ref(db, userSettingsPath);
    update(settingsRef, { showCount: checked });
  };

  const handleSave = () => {
    update(ref(db, boardPath), {
      title,
      background,
    }).then(onClose);
  };

  const handleBackgroundUpload = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }
    setUploading(true);
    const storageReference = storageRef(storage, `backgrounds/${boardId}`);
    await uploadBytes(storageReference, file);
    const url = await getDownloadURL(storageReference);
    setBackground(url);
    setUploading(false);
  };

  const handleDelete = () => {
    setConfirmOpen(false);
    remove(ref(db, boardPath)).then(() => {
      onClose();
    });
  };

  const handleOpenConfirm = () => {
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
  };

  const handleLeaveBoard = async () => {
    setConfirmOpen(false);

    const leaveBoardFunction = httpsCallable(functions, "leaveBoard");
    try {
      await leaveBoardFunction({
        boardId,
        userUid: currentUser.uid,
        ownerUID: boardOwner,
      });
      onClose();
    } catch (error) {
      console.error("Error leaving the board:", error);
    }
  };

  function handleClose() {
    onClose();
  }

  return (
    <>
      {loading && open && (
        <div>
          <CircularProgress sx={{ color: "#A0AD39" }} />
        </div>
      )}
      {!loading && open && (
        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              color: themeDetails[settings.cardColor]?.font || "black",
              backgroundColor:
                themeDetails[settings.cardColor]?.primary || "white",
            },
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <TeldioTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{ width: "100%" }}
              textColor="#A0AD39"
            >
              <TeldioTab
                sx={{ width: boardOwner === "own" ? "50%" : "100%" }}
                label="Board User"
                {...a11yProps(0)}
              />
              {boardOwner === "own" && (
                <TeldioTab
                  sx={{ width: "50%" }}
                  label="Board Admin"
                  {...a11yProps(1)}
                />
              )}
            </TeldioTabs>
          </Box>

          <CustomTabPanel
            value={value}
            index={0}
            backgroundColor={
              themeDetails[settings.cardColor]?.primary || "white"
            }
            color={themeDetails[settings.cardColor]?.font || "black"}
          >
            <Box sx={{ width: 300 }}>
              <Box
                position="relative"
                display="flex"
                alignItems="center"
                width="100%"
                sx={{ marginBottom: 1 }}
              >
                <Typography
                  variant="h6"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  Board Settings
                </Typography>
              </Box>
              <Divider
                sx={{
                  backgroundColor:
                    themeDetails[settings.cardColor]?.font || "black",
                  opacity: "0.5",
                }}
              />

              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1">Show Archived Cards</Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                >
                  <TeldioSwitch
                    checked={showingArchived}
                    onChange={(e) => {
                      handleToggleViewArchived(e);
                      e.stopPropagation();
                    }}
                  />
                </div>
              </Box>

              <Divider
                sx={{
                  marginY: 2,
                  backgroundColor:
                    themeDetails[settings.cardColor]?.font || "black",
                  opacity: "0.5",
                }}
              />

              <Box
                position="relative"
                display="flex"
                alignItems="center"
                width="100%"
                sx={{ marginBottom: 1 }}
              >
                <Typography
                  variant="h6"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  My Settings
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                  Card Theme
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                >
                  {themeOptions.map((option) => (
                    <Box
                      key={option.color}
                      onClick={() =>
                        handleColorChange(option.color, option.title)
                      }
                      sx={{
                        width: 50,
                        height: 50,
                        backgroundColor: option.color,
                        borderRadius: "8px",
                        margin: "0 5px",
                        cursor: "pointer",
                        border:
                          selectedColor === option.title
                            ? "3px solid #A0AD39"
                            : "3px solid lightgrey",
                      }}
                    />
                  ))}
                </Box>
              </Box>
              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                  Show Card Count
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                >
                  <TeldioSwitch
                    checked={showingCardCount}
                    onChange={(e) => {
                      handleToggleShowCount(e);
                      e.stopPropagation();
                    }}
                  />
                </div>
              </Box>
              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1">
                  Marek Style Comments (Reversed)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                >
                  <TeldioSwitch
                    checked={reverseComments}
                    onChange={(e) => {
                      handleToggleReverseComments(e);
                      e.stopPropagation();
                    }}
                  />
                </div>
              </Box>
              <Divider
                sx={{
                  marginY: 2,
                  backgroundColor:
                    themeDetails[settings.cardColor]?.font || "black",
                  opacity: "0.5",
                }}
              />
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                Board Members
              </Typography>
              <div style={{ maxHeight: "400px", overflow: "auto" }}>
                {convertToArray(collaborators).map((member) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Avatar
                      src={member.photoURL}
                      alt={member.displayName || member.email}
                      sx={{ marginRight: "10px" }}
                    />
                    <Typography>
                      {member.displayName || member.email}
                    </Typography>
                  </div>
                ))}
              </div>

              <Divider
                sx={{
                  marginY: 2,
                  backgroundColor:
                    themeDetails[settings.cardColor]?.font || "black",
                  opacity: "0.5",
                }}
              />
              {boardOwner === "own" ? (
                <Button
                  fullWidth
                  color="error"
                  variant="contained"
                  sx={{ marginTop: 2 }}
                  onClick={handleOpenConfirm}
                >
                  Delete Board
                </Button>
              ) : (
                <Button
                  fullWidth
                  color="error"
                  variant="contained"
                  sx={{ marginTop: 2 }}
                  onClick={handleOpenConfirm}
                >
                  Leave Board
                </Button>
              )}
            </Box>
            <Dialog open={confirmOpen} onClose={handleCloseConfirm}>
              <DialogTitle>
                {boardOwner === "own"
                  ? "Are you sure you want to delete this board?"
                  : "Are you sure you want to leave this board?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {boardOwner === "own"
                    ? "This action cannot be undone. All data associated with this board will be permanently deleted."
                    : "You will no longer have access to this board."}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseConfirm} color="primary">
                  Cancel
                </Button>
                {boardOwner === "own" ? (
                  <Button
                    onClick={handleDelete}
                    color="error"
                    autoFocus
                    variant="contained"
                  >
                    Delete
                  </Button>
                ) : (
                  <Button
                    onClick={handleLeaveBoard}
                    color="error"
                    autoFocus
                    variant="contained"
                  >
                    Leave
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={1}
            backgroundColor={
              themeDetails[settings.cardColor]?.primary || "white"
            }
            color={themeDetails[settings.cardColor]?.font || "black"}
          >
            {boardOwner === "own" && (
              <Box sx={{ width: 300 }}>
                <Box
                  position="relative"
                  display="flex"
                  alignItems="center"
                  width="100%"
                  sx={{ marginBottom: 1 }}
                >
                  <Typography
                    variant="h6"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    Board Settings
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    backgroundColor:
                      themeDetails[settings.cardColor]?.font || "black",
                    opacity: "0.5",
                  }}
                />
                <Box sx={{ marginTop: 2 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ marginTop: 2, textAlign: "center" }}
                  >
                    Board Title
                  </Typography>
                  <TeldioTextField
                    value={title}
                    placeholderColor={
                      themeDetails[settings.cardColor]?.font || "black"
                    }
                    onChange={handleTitleChange}
                    fullWidth
                    margin="normal"
                    disabled={boardOwner !== "own"}
                  />
                  <RealDivider />
                  <Typography
                    variant="subtitle1"
                    sx={{ marginTop: 2, textAlign: "center" }}
                  >
                    Background
                  </Typography>
                  {background && background.includes("https") && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "5px",
                        border: "solid",
                        borderColor: "#A0AD39",
                        padding: "5px",
                        width: "290px",
                        height: "150px",
                        backgroundColor: "#F1F2F4",
                      }}
                    >
                      {!uploading && (
                        <img
                          src={background}
                          alt="Background"
                          style={{ width: "280px", height: "auto" }}
                        />
                      )}
                    </div>
                  )}
                  <SquaredButtonGreen
                    variant="contained"
                    fullWidth
                    component="label"
                    sx={{ marginTop: 1, marginBottom: 2 }}
                    disabled={uploading}
                  >
                    {!uploading ? (
                      "Upload Image"
                    ) : (
                      <CircularProgress size={24} sx={{ color: "grey" }} />
                    )}
                    <input
                      type="file"
                      hidden
                      onChange={handleBackgroundUpload}
                    />
                  </SquaredButtonGreen>
                  <Typography
                    sx={{ marginTop: 2, textAlign: "center", fontSize: "10pt" }}
                  >
                    Or
                  </Typography>
                  <TeldioTextField
                    select
                    inputProps={{
                      MenuProps: {
                        MenuListProps: {
                          sx: {
                            backgroundColor:
                              themeDetails[settings.cardColor]?.primary ||
                              "white",
                            color:
                              themeDetails[settings.cardColor]?.font || "black",
                          },
                        },
                      },
                    }}
                    label="Preset Color"
                    value={background}
                    onChange={handleBackgroundChange}
                    fullWidth
                    margin="normal"
                    placeholderColor={
                      themeDetails[settings.cardColor]?.font || "black"
                    }
                  >
                    {colors.map((color) => (
                      <MenuItem
                        key={color}
                        value={color}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor:
                              themeDetails[settings.cardColor]?.primary,
                            color:
                              themeDetails[settings.cardColor]?.font || "white",
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor:
                              themeDetails[settings.cardColor]?.dark || color,
                          },
                          "&:hover": {
                            backgroundColor:
                              themeDetails[settings.cardColor]?.dark || color,
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            height: "20px",
                            backgroundColor: color,
                          }}
                        />
                      </MenuItem>
                    ))}
                  </TeldioTextField>
                </Box>
                <Button
                  fullWidth
                  color="success"
                  variant="contained"
                  sx={{ marginTop: 2 }}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>
            )}
          </CustomTabPanel>
        </Drawer>
      )}
    </>
  );
};

export default SettingsSidebar;
